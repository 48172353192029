@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;900&display=swap');

:root {
    --background: #f9f9f5;
    --main-font-color: #161819;
}

*, *::before, *::after { box-sizing: border-box; }
html { box-sizing: border-box; }
html, body { margin: 0; padding: 0; min-height: 100%; }

body {
    background: #fff;
    color:var(--main-font-color);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    padding:80px 0px 0px;
    margin:0 auto;
    min-height: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased !important;
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";
}

body.admin {
    padding:0px;
}

a {
    color: #0073a0;
    text-decoration: underline #ccc;
}

a:hover {
    color: #04668c;
    text-decoration: underline #0073a0;
}

h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}


.fr {
    float:right;
}

.fl {
    float:left;
}

main {
    width: calc(100% - 0px);
    margin: 0px auto;
    max-width:1640px;
    padding: 0 0px;
}

main.website {
    max-width:1240px;
}

main h1 {
    font-size: 20px;
    font-weight: 500;
}

main h1 span {
    opacity: 0.5;
}

div.admin-system {
    width: calc(100% - 220px);
    margin: 0px 0 0 220px;
    padding: 0 20px;
    min-height: 100vh;
    top: 0;
    position: relative;
}

div.admin-system.logged-in-user {
    width: calc(100% - 470px);
}

.wrapper {
    max-width: 1240px;
    width:100%;
    margin:0 auto;
    padding: 0 20px;
}

.zztop {
    padding-top: 150px;
}


.backpeace {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left:0;
    z-index: 0;
}

.eittsett {
    min-height: 100vh;
    min-width: 100%;
    position: relative;
    z-index: 1;
}

.kulaeitt {
    position: absolute;
    top:calc(50% - 250px);
    height:700px;
    width:700px;
    background-color: #1991ee;
    border-radius: 350px;
    right:-50px;
    z-index: 0;
    filter: blur(350px);
    opacity: 0.05;
}

.kulatvo {
    position: absolute;
    top:calc(0% - 50px);
    height:700px;
    width:700px;
    background-color: #1991ee;
    border-radius: 350px;
    left:-150px;
    z-index: 0;
    filter: blur(350px);
    opacity: 0.05;
}


footer {
    position:relative;
    left:0px;
    bottom: 0px;
    height:34px;
    line-height: 34px;
    background: rgba(255,255,255,1);
    z-index: 1;
    width:calc(100% - 0px);
    padding: 0px;
    transition: 0.3s ease;
    margin-bottom: 0px;
    clear: both;
    transition: 0.2s ease;
    border-top: 1px solid rgba(0,0,0,0.05);
    font-size: 15px;
}

footer ul {
    margin:0;
    list-style-type: none;
    padding:0px;
}

footer ul li {
    display:inline-block;
    margin-right: 15px;
}

.siteinfo {
    width:50%;
    display: inline-block;
}

.contactinfo {
    width:50%;
    display: inline-block;
    text-align: right;
}

.contactinfo a {
    color:#df1a53;
    text-decoration: none;
}

.newmessage {
    margin: 0 auto;
    width:100%;
    min-height:410px;
    padding:20px 0;
    background-color: #fff;
    border-radius:5px;
    margin-top:30px;
}

.messagetable {
    width:100%;
}

.messagetable tr td {
    vertical-align: top;
}

.messagetable tr td:first-child {
    width: 25%;
    font-size: 16px;
    padding-top: 18px;
}

.messagetable tr td:last-child {
    width: 75%;
}

.innskraningssida {
    margin: 0 auto;
    width:100%;
    max-width:480px;
    min-height:410px;
    padding:20px 0;
    background-color: #fff;
    border-radius:5px;
    margin-top:30px;
}

.newmessage_btn {
    border: 1px solid #e65150;
    display: block;
    padding: 8px 16px;
    margin-top: -9px;
    border-radius: 5px;
    background: #fff;
    color: #000;
    transition: .30s ease-in-out;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    width:auto;
    text-align: center;
    text-decoration: none;
    margin-top:20px;
}

.loginmethod {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin:10px 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    width:100%;
}

.loginmethod li {
    width: 43%;
    background: #f1f1f1;
    padding: 10px;
    text-align: center;
    border: 1px solid #f1f1f1;
    color:#585858;
    cursor: pointer;
}

.loginmethod li.active {
    background-color: #00A9CC;
    color:#fff;
}

.loginmethod li.fasms {
    width: 25%;
    margin:0 10px;
}

.loginmethod li.kenni {
    width: 32%;
}

.tabcontent {
    display: none;
    min-height: 300px;
}

.tabcontent.active {
    display: block;
}

.tabcontent h3 {
    font-size: 17px;
    font-weight: 400;
}

.innskraningssida select, .newmessage select {
    width: 100%;
    border: 1px solid #888988;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:5px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
}

.innskraningssida input, .newmessage input {
    width: 100%;
    border: 1px solid #888988;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:5px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
}

.innskraningssida textarea, .newmessage textarea {
    width: 100%;
    border: 1px solid #888988;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:5px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
}

.innskraningssida button {
    width: 100%;
    border: 1px solid #0095f9;
    background-color: #0095f9;
    color:#fff;
    padding:8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius:5px;
    transition: 0.3s ease;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.innskraningssida button:hover {
    background-color:#047ccd;
}

.innskraningssida .gleymt {
    margin: 10px 0 25px;
    font-size: 17px;
}

.innskraningssida .gleymt a {
    color:#888988;
    text-decoration: none;
}

h3.login {
    font-size: 20px;
    font-weight:400;
    text-align: center;
    margin: 100px 0 0;
    color:#888988;
}

h2.login {
    font-size: 30px;
    font-weight:700;
    text-align: center;
    margin: 0px;
    color:#303030;
}

p.login {
    font-size: 18px;
    font-weight:400;
    text-align: center;
    margin:10px 0 30px;
}

h2.nyskra {
    font-size: 30px;
    font-weight:700;
    text-align: center;
    margin: 0px 0 20px;
    color:#303030;
}

h1.skrakast {
    margin-bottom: 0;
}

h2.skrakast_upplysingar {
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 300;
}

h4.litakodakerfid {
    margin: 30px 0 5px;
    font-weight: 500;
}

.loginbtn {
    border: 1px solid #df1a53 !important;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    background-color: #df1a53 !important;
    padding:10px 0;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

.loginbtn.disabled {
    opacity: 0.5;
}

.loginbtn:hover {
    background-color: #b50639 !important;

}

.brakethis {
    display: none;
}


.buatilnotenda {
    margin-top:20px !important;
    cursor: pointer;

}

.itemfull {
    width:100%;
    background:#fff;
    margin:16px 0;
    border-radius: 5px;
    padding:24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.itemfull .icon {
    height:60px;
    width:80px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.itemfull .icon h3 {margin: 0;}

.itemfull .info {
    min-height:60px;
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.itemfull .icon i {
    font-size: 60px;
}

.itemfull h4 {
    margin: 0;
}

.itemfull .moreInfo  {
    display:none;
}

.aukaupp {
    font-size:14px;
}

.question {
    width:100%;
    padding:10px;
}

.question label {
    display: inline-block;
}

.urgency {
    width:100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    border: 1px solid #dedede;
    margin:2px 0;
    float: left;
}

.urgency a {
    color:#1f1d1d;
    text-decoration: none;
}

.innskraning {
    float: right;
}

.addUrgency {
    float: right;
    display: inline-block;
    padding: 5px 10px;
    background:transparent;
    border:1px solid #e65150;
    color:#333;
    font-size: 16px;
    text-decoration: none;
    border-radius:5px;
    transition: 0.3s ease;
}

.addUrgency i {
    margin-right: 5px;
    color:#e65150;
    transition: 0.3s ease;
}

.addUrgency:hover {
    color:#fff;
    background:#e65150;
    text-decoration: none;
}

.addUrgency:hover i {
    color:#fff;
}

select, input {
    border:1px solid #999;
    background:rgba(255,255,255,0.7);
    border-radius: 5px;
    padding:5px 10px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.react-datetime-picker__wrapper {
    border:1px solid #999;
    background:rgba(255,255,255,0.7);
    border-radius: 5px;
}

.urgencies .content {
    float: right;
    margin-top: -64px;
}

.urgency h3 {
    text-transform: uppercase;
    margin:0;
    font-size: 16px;
}

.urgency h4 {
    margin:0 0 3px;
    font-weight: normal;
    font-size: 16px;
}

.urgency span {
    color: #939393;
}

.urgency strong {
   font-weight: 400;
}

.urgency p {
    margin:0;
    font-weight: normal;
    font-size: 16px;
}

section .background_mynd {
    width: 45%;
    height: 500px;
    top: 0;
    right: 0;
    position: absolute;
    text-align: left;
    overflow: hidden;
    z-index: 1;
    padding-left: 90px;
}

section .background_mynd img {
    max-width: 400px;
    margin: 100px auto 0;
}

.skra_mainwin {
    width: calc(100% - 420px);
    display: inline-block;
    vertical-align: top;
}

.skra_upplysingar {
    width: 420px;
    display: inline-block;
    vertical-align: top;
}

.alvarleikikasts {
    padding: 10px;
    border: 1px solid rgba(184, 0, 0, 1);
    background-color:rgba(184, 0, 0, 0.1);
    font-size: 16px;
    border-radius: 6px;
}

.alvarleikikasts.l_5ccc1a {
    border: 1px solid rgba(92, 204, 26, 1) !important;
    background-color:rgba(92, 204, 26, 0.1);

}
.alvarleikikasts.l_c5e70b {
    border: 1px solid rgba(197, 231, 11, 1) !important;
    background-color:rgba(197, 231, 11, 0.1);

}

.alvarleikikasts.l_f1820b {
    border: 1px solid rgba(241, 130, 11, 1) !important;
    background-color:rgba(241, 130, 11, 0.1);

}



.alvarleikikasts p { margin: 0 0 5px 0; }

.buatilnotenda {
    border: 1px solid #e65150;
    display: block;
    padding: 8px 16px;
    margin-top: -9px;
    border-radius: 5px;
    background: #fff;
    color: #000;
    transition: .30s ease-in-out;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    width:100%;
    text-align: center;
    text-decoration: none;
}

.buatilnotenda:hover {
    background: #e65150;
    color:#fff;
}


.eydakasti_btn {
    border: 1px solid #ccc;
    display: block;
    padding: 8px 16px;
    margin-top: -9px;
    border-radius: 5px;
    background: #fff;
    color: #000;
    transition: .30s ease-in-out;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    width:100%;
    text-align: center;
    text-decoration: none;
}

.eydakasti_btn:hover {
    background: #e65150;
    color:#fff;
}

h1.page-title {
    margin: 20px 0 30px;
    font-size: 18px;
    font-weight: 500;
}

h1.page-title span, h3 span {
    color:#1297c7;
}

h1.page-title a {
    float:right;
    border: 1px solid #e9e9e9;
    display: block;
    padding: 8px 12px;
    border-radius: 5px;
    transition: .30s ease-in-out;
    font-size: 16px;
    text-decoration: none;
    margin-top: -8px;
}

h3.page-title {
    margin: 10px 0 10px;
    font-size: 18px;
    font-weight: 500;
}

h3.questions {
    font-weight: 500;
    margin: 0px 0 10px;
}

h4.questions {
    font-weight: 400;
    margin: -5px 0 20px;
}

.alpha {
    width: calc(50% - 20px);
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.gamma {
    width: calc(50% - 20px);
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    margin: 0 10px;
}

.alphagamma {
    width: calc(66.66664% - 20px);
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.delta {
    width: calc(33.33334% - 20px);
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
}

.alpha h3, .alphagamma h3, .gamma h3, .delta h3 {
    margin:0 0 10px;
    font-size: 18px;
    font-weight: 500;
}

.alpha h3 a, .alphagamma h3 a, .gamma h3 a, .delta h3 a {
    float: right;
}

.bradarkast {
    width:100%;
    margin: 0 0 7px;
    padding-bottom: 1px;
    border: 1px dotted #bfb9ad;
    background-color: #fefefc;
    padding:5px;
    border-radius: 5px;
}

.bradarkast .dagur {
    width:40px;
    height:40px;
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    padding:4px 2px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    text-align: center;
    border-radius:5px;
}

.bradarkast .dagur strong {
    display: block;
    font-size: 15px;
    line-height: 1;
}

.bradarkast .lysing {
    width:calc(100% - 55px);
    height:40px;
    display: inline-block;
    vertical-align: top;
    padding:2px 5px;
    margin-left: 5px;
}

.bradarkast .lysing h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    float: none;
}

.bradarkast .lysing h3 a {
    float: none;
    color:#1f1d1d;
    text-decoration: none;
    font-weight: 700;
}

.bradarkast .lysing p {
    margin: 0;
    font-size: 15px; 
}

.liturf1820b {
    border: 1px solid #f1820b !important;
    background:#f1820b !important;
    color: #fff !important;
}

.liturb80000 {
    border: 1px solid #b80000 !important;
    background-color:#b80000 !important;
    color: #fff !important;
}

.liturc5e70b {
    border: 1px solid #9cb803 !important;
    background-color:#9cb803 !important;
    color: #fff !important;
}

.litur5ccc1a {
    border: 1px solid #4dab15 !important;
    background-color:#4dab15 !important;
    color: #fff !important;
}

.verkin .item {
    width: 100%;
    padding: 10px;
    margin-bottom:10px;
    border-radius:5px;
}

.verkin .item.selected {
    background-color: #fff;
}

.verkin .item .mynd {
    height:50px;
    width:50px;
    display: inline-block;
    background-color: #fff;
    border:1px solid rgba(25, 145, 238,0.5);
    border-radius: 25px;
    vertical-align: top;
    display: none;
}

.verkin .item .upplysingar {
    display: inline-block;
    vertical-align: top;
    margin:5px 0 0 10px;
    color:rgb(181, 181, 181);
    font-size: 15px;
}

.verkin.skoda .item:first-child {
    background:#6df0f1;
}

.verkin .item h3 {
    font-size: 16px;
    margin: 0 0 5px;
}
.verkin .item h3 a {
    text-decoration: none;
    color:#1f1d1d;
}

.verkin .item p {
    font-size: 16px;
    margin: 0;
}


.lyfinmin {
    width:100%;
    height:auto;
    margin:0 0 10px 0;
    font-size:16px;
}

.staff {
    width:100%;
    border: 1px solid #e0e7ec;
    background-color: #fff;
    height:102px;
    border-radius:5px;
    margin:10px 0;
    padding:10px;
    line-height:1.3;
}

.staff .mynd {
    height:80px;
    width:80px;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #e0e7ec;
    background-color: #f9f9f9;
    vertical-align: top;
}

.staff .mynd figure {
    width:80px;
    height:80px;
    padding:0;
    background-size: cover;
    margin:0;
}

.staff .upplysingar {
    height:60px;
    width:calc(100% - 100px);
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    font-size: 15px;
}

.verkin {
    font-size: 16px;
    width:30%;
    display: inline-block;
    vertical-align: top;
    min-height: calc(100vh - 100px);
}

.verkid {
    font-size: 16px;
    width:70%;
    display: inline-block;
    vertical-align: top;
    padding-left: 40px;
}

.verkid h3 { 
    margin:0;
    font-size: 22px;
}

.verkid h4 { 
    margin:5px 0 0;
    font-weight: 400;
}

.verkid .skilabodin {
    margin-top:20px;
} 

.urgencies, .samkiptabox {
    min-height: 240px;
}

.urgencies.wb, .samkiptabox.wb, .lyfinmin.wb {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 5px;
    min-height: 300px;
}

.urgencies.listinn {
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    padding: 0;
}

.sjaallt {
    width: 100%;
    margin: 10px 0 5px;
}

.sjaallt a {
    display: inline-block;
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 5px;
    color:#242424;
    transition: 0.3s ease-in-out;
    font-weight: 600;
}

.sjaallt a:hover {
    color:#1297c7;
}


.fiftycent {
    display: inline-block;
    width:calc(50% - 20px);
    margin: 0 20px 0 0;
    vertical-align: top;
}

.fiftycent:nth-child(2) { 
    margin: 0 0 0 20px;
}

.yfirlit {
    width: 100%;
    float: left;
}

.yfirlit.b {
    background-color: #fdfcfb;
    border: 1px dotted #e5e1d9;
}

table.table {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

table.table thead th {
    padding: 10px;
    text-align: left;
    font-weight: 500;
    font-size: 15px;
}

table.table tbody td {
    padding: 10px;
    text-align: left;
    font-weight: 500;
    border-top:  1px solid #eeeeee;
    border-right:  1px solid #eeeeee;
    font-size: 15px;
    font-weight: 400;
}

table.table tbody td:last-child {
    border-right:  0px solid #eeeeee;
}

.formid input {
    width: 100%;
    margin:10px 0;
}

.slidecontainer {
    width: 100%;
}

.slidecontainer.info {
    width: 100%;
    margin: 5px 0 10px;
    height: 20px;
    color:#ccc;
    font-size: 16px;
}

.slidecontainer .min {
    float: left;
}

.slidecontainer .max {
    float: right;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
    background: #e9e9e9;
    outline: none;
    opacity: 0.9;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 10px;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #5f58a6;
    cursor: pointer;
    border-radius: 50%; 
}

.slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #5f58a6;
    cursor: pointer;
    border-radius: 10px;
}

.slider-orange > .bar > span {
    transition: 0.3s;
    background: #ff821e!important;
}

.slider-orange > .bar-btn{
    background: #ff821e!important;
    border: 2px solid #ffbf8c !important;
    transition: 0.3s;
}

.slider-orange:hover > input + .slider-container > .bar-btn {
    box-shadow: inset 0 0 2px #ff821e;
    color: #ff821e;
    background: #fff !important;
    border: 2px solid #ff821e !important;
}

.filters {
    width:100%;
    height:30px;
    display: block;
}

.select-box {
    position: relative;
    display: block;
    width: 220px;
    margin: 0 auto;
    font-size: 16px;
    color: #60666d;
    float: right;
}

.select-box__current {
    position: relative;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
}

.select-box__current:focus + .select-box__list {
    opacity: 1;
    -webkit-animation-name: none;
            animation-name: none;
}

.select-box__current:focus + .select-box__list .select-box__option {
    cursor: pointer;
}

.select-box__current:focus .select-box__icon {
    transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    transition: 0.2s ease;
}

.select-box__value {
    display: flex;
}

.select-box__input {
    display: none;
}

.select-box__input:checked + .select-box__input-text {
    display: block;
}

.select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px;
    background-color: #2ac2d3;
    border-radius: 10px;
}

.select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    -webkit-animation-name: HideList;
            animation-name: HideList;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
            animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.select-box__option {
    display: block;
    padding: 15px;
    background-color: #2ac2d3;
}

.select-box__option:hover, .select-box__option:focus {
    color: #546c84;
    background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}

@keyframes HideList {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}

h3.section_heading {
    border-bottom: 1px dotted #2ac2d3;
    padding: 0 0 5px;
    color:#484848;
    font-weight: 500;
    font-size: 20px;
    margin-top: 10px;
}

h3.section_heading.top {
    margin-top: 0;
}


.appointments {
    width:100%;
}

.appointment {
    width: 100%;
    margin: 0 0 7px;
    padding-bottom: 1px;
    border: 1px dotted #bfb9ad;
    background-color: rgba(255,255,255,0.8);
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
}

.appointment .time {
    display: inline-block;
    text-align: right;
    font-size: 15px;
    width: 30%;
}

.appointment .doctor {
    display: inline-block;
    width: 70%;
    vertical-align: top;
}

.appointment .doctor .name {
    font-weight: 700;
}

.appointment .date .image {
    height:44px;
    width:44px;
    background:#0062FF;
    opacity: 0.1;
    border-radius: 22px;
}

.notandinn {
    width:100%;
    margin:40px 0;
}

.notandinn div {
    margin:10px 0;
    width: 100%;
}

.mymedicine {
    width:100%;
}

.mymedicine td { text-align: center; }

.mymedicine thead th:first-child, .mymedicine tbody td:first-child {
    text-align: left;
}

.mymedicine td .icon0 {
    height:16px;
    width: 16px;
    border:1px solid #2ac2d3;
    border-radius: 10px;
    margin: 0 auto;
}

.mymedicine td .icon1 {
    height:14px;
    width: 14px;
    border:1px solid #148491;
    background-color: #2ac2d3;
    border-radius: 10px;
    margin: 2px auto;
}

.checkPasswordstrength {
    position: relative;
    margin-bottom: 10px;
}

.errorcolor {
    width: 16px;
    height: 16px;
    border-radius: 12px;
    margin:1px;
}

.errorcolor.stig1 {
    width: 50%;
}

.errorcolor.stig2 {
    width: calc(100% - 2px);
}

.breytalykilordi {
    max-width: 500px;
    width: 100%;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.breytalykilordi input {
    width:100%;
}

.breytalykilordi input {
    width: 100%;
    border: 1px solid #888988;
    padding: 8px;
    font-size: 16px;
    margin: 5px 0;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
}

.endurstillabtn {
    width: 100%;
    border: 1px solid #ff455f !important;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    background-color: #ff455f !important;
    padding: 10px 0;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 18px;
    margin: 5px 0;
    border-radius: 5px;
}

.passcheck {
    width: 30%;
    height:20px;
    border-radius: 12px;
    border:1px solid #ccc;
    position: absolute;
    right: 8px;
    top: 17px;
}

.changePass {
    width: calc(33.33334% - 20px);
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.changePass .breytalykilordi {
    margin: 0 0 40px 0 !important;
}

.newpass_btn {
    background-color: #ff455f;
    color: #fff;
    border: 1px solid #ff455f;
    width:100px;
    padding: 5px;
    margin-top: 8px;
    font-size: 18px;
    border-radius: 6px;
}

.newpass_btn.disabled {
    opacity: 0.2;
}

.lifsgaeda_flokkur {
    display: inline-block;
    width:50%;
    padding: 0 50px 0 0;
    vertical-align: top;
    margin: 0 auto;
}

.lifsgaeda_flokkur h2 {
    font-size: 20px;
    border-bottom: 1px solid rgba(197, 63, 61, 0.5);
    color:#c53f3d;
    margin: 30px 0 20px;
    padding-bottom: 5px;
}

.lifsgaeda_flokkur h3 {
    font-size: 18px;
}

.nyskramessage {
    width: 100%;
    padding: 0px 0;
    font-size: 16px;
    color:#2ac2d3;
}

.nyskraerrormessage {
    width: 100%;
    padding: 0px 0;
    font-size: 16px;
    color:#ff455f;
}

.notandistadsetting {
    font-family: 'Poppins', sans-serif;
    width:100%;
    padding: 0px;
    display: inline-block;
    vertical-align: top;
}

.stadur_h {
    display: inline-block;
    width: 50%;
    font-size: 16px;
    margin:2px 0;
}

.stadur_f {
    display: inline-block;
    width: 50%;
    font-size: 16px;
    margin:2px 0;
    text-align: right;
}

.gradid {
    width:100%;
    padding: 20px;
    display: inline-block;
    vertical-align: top;
}

.gradid .manudur {
    display: inline-block;
    width: 33.33333%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    padding: 0 20px;
}

.gradid .manudur .samtals {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px dotted #c4c4c3;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.gradid .manudur .overuleg {
    width: 25%;
    display: inline-block;
    text-align: center;
}

.gradid .manudur .vaeg  {
    width: 25%;
    display: inline-block;
    text-align: center;
}

.gradid .manudur .midlungs  {
    width: 25%;
    display: inline-block;
    text-align: center;
}

.gradid .manudur .alvarlegt {
    width: 25%;
    display: inline-block;
    text-align: center;
}

.gradid .manudur .maeling {
    height:100px;
    width: 10px;
    margin: 0 auto 10px;
    background-color: #f8f8f8;
    display: block;
    text-align: center;
    position: relative;
}

.gradid .manudur .alvarlegt .maeling .eining {
    background-color: #ba4e45;
}

.gradid .manudur .midlungs .maeling .eining {
    background-color: #e8a05d;
}

.gradid .manudur .vaeg .maeling .eining {
    background-color: #d6eb6f;
}

.gradid .manudur .overuleg .maeling .eining {
    background-color: #95d468;
}

.gradid .manudur .maeling .eining {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 10px;
    border-radius: 5px 5px 0 0;
}

.gradid .manudur .maeling .eining.e_1 {
    position: absolute;
    bottom: 0;
    height: 10px;
}

.gradid .manudur .maeling .eining.e_2 {
    position: absolute;
    bottom: 0;
    height: 20px;
}

.gradid .manudur .maeling .eining.e_3 {
    position: absolute;
    bottom: 0;
    height: 30px;
}

.sjuklingur_upplysingar {
    width: calc(100% - 40%);
    display: inline-block;
    vertical-align: top;
}

.sjuklingur_notkun {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}

.sjuklingur_notkun .fiftycent {
    width: calc(100% - 0px);
}

.sjuklingsform label {
    width:100%;
    display: block;
    margin: 10px 0 5px;
}

.sjuklingsform input {
    width: 350px;
}

.sjuklingsform input#tel, .sjuklingsform input#ssn {
    width: 140px;
}

.sjuklingsform  .buatilnotenda {
    width: 300px;
}

.lokakasti {
    display: inline-block;
    width: 50%;
    padding-right: 20px;
    vertical-align: top;
}

.upplysingarumkast {
    display: inline-block;
    width: 50%;
    padding-left: 20px;
    vertical-align: top;
}

.mobile-header {
    height: 50px;
    width: calc(100% - 20px);
    display: none;
    position: absolute;
    z-index: 0;
}

.mobile-header h1 {
    margin: 0;
}

.mobile-header h1.logo {
    font-size: 0px;
    display: inline-block;
    margin: 0 auto;
    float: none;
}

.mobile-header h1.logo::before {
    display: none;
}

.mobile-header h1.logo span {
    display: block;
    height: 40px;
    width: 40px;
    top: -5px;
    left: 0px;
    background-image: url(../src/img/fruma_merki.png);
    z-index: 1;
    background-size: cover;
    position: absolute;
}

.screen {
    display: inline-block;
    padding: 0px;
    width: 100%;
}

#app {
    height: 96px;
    width: 96px;
    display: inline-block;
    vertical-align: top;
}

#app_info {
    width:calc(100% - 100px);
    display: inline-block;
    vertical-align: top;
    padding: 10px 0 0;
}

@-webkit-keyframes RingProgress {
    0% {
        stroke-dasharray: 0 100;
    }
}

@keyframes RingProgress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.lifsgaedi .lifsgaedi_heildarskor {
    width:30%;
    height:50px;
    display: inline-block;
    vertical-align: top;
}

.lifsgaedi .lifsgaedi_verkir {
    width:14%;
    height:50px;
    display: inline-block;
    vertical-align: top;
}

.lifsgaedi .lifsgaedi_verkir.litur_0 .ring1 .background {
    stroke: rgba(25, 156, 201, 0.4);
}

.lifsgaedi .lifsgaedi_verkir.litur_0 .ring1 .completed {
    stroke: rgba(25, 156, 201, 1);
}

.lifsgaedi .lifsgaedi_verkir.litur_1 .ring1 .background {
    stroke: rgba(92, 204, 26, 0.4);
}

.lifsgaedi .lifsgaedi_verkir.litur_1 .ring1 .completed {
    stroke: rgba(92, 204, 26, 1);
}

.lifsgaedi .lifsgaedi_verkir.litur_2 .ring1 .background {
    stroke: rgba(197, 231, 11, 0.4);
}

.lifsgaedi .lifsgaedi_verkir.litur_2 .ring1 .completed {
    stroke: rgba(197, 231, 11, 1);
}

.lifsgaedi .lifsgaedi_verkir.litur_3 .ring1 .background {
    stroke: rgba(241, 130, 11, 0.4);
}

.lifsgaedi .lifsgaedi_verkir.litur_3 .ring1 .completed {
    stroke: rgba(241, 130, 11, 1);
}

.lifsgaedi .lifsgaedi_verkir.litur_4 .ring1 .background {
    stroke: rgba(184, 0, 0, 0.4);
}

.lifsgaedi .lifsgaedi_verkir.litur_4 .ring1 .completed {
    stroke: rgba(184, 0, 0, 1);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_0 .ring1 .background {
    stroke: rgba(25, 156, 201, 0.4);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_0 .ring1 .completed {
    stroke: rgba(25, 156, 201, 1);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_1 .ring1 .background {
    stroke: rgba(92, 204, 26, 0.4);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_1 .ring1 .completed {
    stroke: rgba(92, 204, 26, 1);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_2 .ring1 .background {
    stroke: rgba(197, 231, 11, 0.4);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_2 .ring1 .completed {
    stroke: rgba(197, 231, 11, 1);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_3 .ring1 .background {
    stroke: rgba(241, 130, 11, 0.4);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_3 .ring1 .completed {
    stroke: rgba(241, 130, 11, 1);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_4 .ring1 .background {
    stroke: rgba(184, 0, 0, 0.4);
}

.lifsgaedi .lifsgaedi_likamlegvirkni.litur_4 .ring1 .completed {
    stroke: rgba(184, 0, 0, 1);
}


.lifsgaedi .lifsgaedi_andleglidan.litur_0 .ring1 .background {
    stroke: rgba(25, 156, 201, 0.4);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_0 .ring1 .completed {
    stroke: rgba(25, 156, 201, 1);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_1 .ring1 .background {
    stroke: rgba(92, 204, 26, 0.4);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_1 .ring1 .completed {
    stroke: rgba(92, 204, 26, 1);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_2 .ring1 .background {
    stroke: rgba(197, 231, 11, 0.4);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_2 .ring1 .completed {
    stroke: rgba(197, 231, 11, 1);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_3 .ring1 .background {
    stroke: rgba(241, 130, 11, 0.4);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_3 .ring1 .completed {
    stroke: rgba(241, 130, 11, 1);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_4 .ring1 .background {
    stroke: rgba(184, 0, 0, 0.4);
}

.lifsgaedi .lifsgaedi_andleglidan.litur_4 .ring1 .completed {
    stroke: rgba(184, 0, 0, 1);
}


.lifsgaedi .lifsgaedi_almennlidan.litur_0 .ring1 .background {
    stroke: rgba(25, 156, 201, 0.4);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_0 .ring1 .completed {
    stroke: rgba(25, 156, 201, 1);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_1 .ring1 .background {
    stroke: rgba(92, 204, 26, 0.4);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_1 .ring1 .completed {
    stroke: rgba(92, 204, 26, 1);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_2 .ring1 .background {
    stroke: rgba(197, 231, 11, 0.4);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_2 .ring1 .completed {
    stroke: rgba(197, 231, 11, 1);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_3 .ring1 .background {
    stroke: rgba(241, 130, 11, 0.4);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_3 .ring1 .completed {
    stroke: rgba(241, 130, 11, 1);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_4 .ring1 .background {
    stroke: rgba(184, 0, 0, 0.4);
}

.lifsgaedi .lifsgaedi_almennlidan.litur_4 .ring1 .completed {
    stroke: rgba(184, 0, 0, 1);
}

.lifsgaedi .lifsgaedi_likamlegvirkni , .lifsgaedi .lifsgaedi_andleglidan, .lifsgaedi .lifsgaedi_almennlidan {
    width:18%;
    height:50px;
    display: inline-block;
    vertical-align: top;
}

.lifsgaedi .lifsgaedi_nytt {
    width:10%;
    height:50px;
    display: inline-block;
    vertical-align: top;
    display: none;
}

.ActivityRings {
    height: 100%;
    width: auto;
    display: inline-block;
    vertical-align: top;
}

.heild {
    height: 100%;
    width: calc(100% - 60px);
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    color:#818a95;
    padding-left: 10px;
    padding-top: 3px;
}

.heild strong {
    display: block;
    font-size: 20px;
    color: #1f1d1d;
}


.ActivityRings .ring {
    transform-origin: 50%;
}

.ActivityRings .completed {
    -webkit-animation: RingProgress 1s ease-in-out forwards;
            animation: RingProgress 1s ease-in-out forwards;
    stroke-linecap: round;
}

.ActivityRings circle {
    fill: none;
}

.ring1 .background {
    stroke: rgba(197, 63, 61, 0.1);
}

.ring1 .completed {
    stroke: #c53f3d;
}

.cat1 {
    margin-top: 0px;
    font-size: 14px;
    padding-left: 5px;
}

.cat1 strong {
    color: #c53f3d;
    font-weight: 600;
    padding-left: 0px;
}

.cat2 {
    margin-top: 1px;
    font-size: 14px;
    padding-left: 5px;
}

.cat2 strong {
    color: #94d55a;
    font-weight: 600;
}

.cat3 {
    margin-top: 1px;
    font-size: 14px;
    padding-left: 5px;
}

.cat3 strong {
    color: #70bed7;
    font-weight: 600;
}

.cat4 {
    margin-top: 1px;
    font-size: 14px;
    padding-left: 5px;
}

.cat4 strong {
    color: #f1820b;
    font-weight: 600;
}

.cat1 span, .cat2 span, .cat3 span, .cat4 span{
    float: right;
}

.ring2 .background {
    stroke: rgba(148, 213, 90, 0.1);
}

.ring2 .completed {
    stroke: #94d55a;
}

.ring3 .background {
    stroke: rgba(112, 190, 215, 0.1);
}

.ring3 .completed {
    stroke: #70bed7;
}

.ring4 .completed {
    stroke: #f1820b;
}

.ring4 .background {
    stroke: rgba(241, 130, 11, 0.1);
}

.ring1 {
    transform: scale(1) rotate(-90deg);
}

.ring2 {
    transform: scale(0.75) rotate(-90deg);
}

.ring3 {
    transform: scale(0.5) rotate(-90deg);
}

.ring4 {
    transform: scale(0.26) rotate(-90deg);
}

.lifsgaeda_flokkur h2.cat2 {border-bottom: 1px solid rgba(148, 213, 90, 0.5); color:#94d55a; margin: 30px 0 20px;padding-bottom: 5px;}
.lifsgaeda_flokkur h2.cat3 {border-bottom: 1px solid rgba(112, 190, 215, 0.5); color:#70bed7; margin: 30px 0 20px;padding-bottom: 5px;}
.lifsgaeda_flokkur h2.cat4 {border-bottom: 1px solid rgba(241, 130, 11, 0.5); color:#f1820b; margin: 30px 0 20px;padding-bottom: 5px;}

h3.heildarskor {
    font-size: 18px;
    font-weight: 700;
    margin:5px 0 0;
    text-align: left;
}

p.heildarskor {
    font-size: 14px;
    margin:0 0 15px;
}

.nyttprof {
    width:50%;
    display: inline-block;
    margin:20px 0 0;
    text-align: left;
}

.nyttprof a {
    display: inline-block;
    padding:5px 20px;
    border: 1px solid #ff455f;
    color:#000;
    text-decoration: none;
    border-radius: 6px;
}

.valspurningar  {
    width:100%;
    display: inline-block;
    vertical-align: top;
}

.svorvidspurnigum  {
    width:50%;
    display: inline-block;
    vertical-align: top;
    padding-left:0px;
}

h2.lifsgaedautskyring {
    font-size: 22px;
    font-weight: 700;
    display: block;
    width:100%;
    margin:15px 0 0;
}

h3.lifsgaedautskyring {
    font-size: 16px;
    font-weight: 400;
    display: block;
    margin:0 0 15px;
}


[type="checkbox"]:checked, [type="checkbox"]:not(:checked), [type="radio"]:checked, [type="radio"]:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}

.checkbox:checked + label:before, .checkbox:not(:checked) + label:before {
	position: absolute;
	font-family: 'unicons';
	cursor: pointer;
	top: -17px;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}

.checkbox:not(:checked) + label:before {
	content: '\eac1';
	left: 0;
	box-shadow: 0 4px 4px rgba(0,0,0,0.15), 0 0 0 1px rgba(26,53,71,0.07);
}

.checkbox:checked + label:before {
	content: '\eb8f';
	left: 30px;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}

.for-checkbox-tools i {
    font-size: 32px;
    margin-bottom: 6px;
}

.checkbox-tools:checked + label, .checkbox-tools:not(:checked) + label {
	position: relative;
	display: inline-block;
	padding: 10px 5px;
	width: calc(20% - 5px);
	font-size: 14px;
	line-height: 1;
	margin: 0 auto;
	margin-right: 5px;
	margin-bottom: 10px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
    min-height: 105px;
}

.checkbox-tools:not(:checked) + label {
/*	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);*/
    border: 2px solid #f8f8f8;
    opacity: 0.6;
}

.checkbox-tools:checked + label{
	background-color: transparent;
/*	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);*/
    border: 2px solid #2ac2d3;
    opacity: 1;
}

.checkbox-tools:not(:checked) + label:hover{
	/*box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);*/
     opacity: 1;
}

.checkbox-tools:checked + label::before, .checkbox-tools:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	z-index: -1;
}

.svorvidspurnigum .cat1 {
    width: 25%;
    display: inline-block;
    font-size: 28px;
    text-align: center;
    font-weight: 900;
}

.svorvidspurnigum .cat1 strong, .svorvidspurnigum .cat2 strong, .svorvidspurnigum .cat3 strong, .svorvidspurnigum .cat4 strong {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    display: block;
    margin-top: 26px;
}

.svorvidspurnigum .cat2 {
    width: 25%;
    display: inline-block;
    font-size: 26px;
    text-align: center;
    font-weight: 900;
}

.svorvidspurnigum .cat3 {
    width: 25%;
    display: inline-block;
    font-size: 26px;
    text-align: center;
    font-weight: 900;
}

.svorvidspurnigum .cat4 {
    width: 25%;
    display: inline-block;
    font-size: 26px;
    text-align: center;
    font-weight: 900;
}

.svorvidspurnigum #app {
    height: 136px;
    width: 196px;
}

.vistaprof {
    background-color: #fff;
    padding: 5px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #e65150;
    font-weight: 700;
    color:#000;
    transition: 0.3s ease;
}

.vistaprof:hover {
    background-color: #e65150;
    color:#fff;
}


.admin-system-header .unread {
    float: right;
    border-left:1px dotted #e5e1d9;
    padding: 3px 0 8px 0px;
    margin: 0 0 0 10px;
    position: relative;
    width:30px;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
}

.admin-system-header .unread i {
    font-size: 21px;
}

.admin-system-header .unread span {
    position:absolute;
    top:-2px;
    right:-8px;
    background:#a30404;
    height:16px;
    width:16px;
    border-radius: 11px;
    color:#fff;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
}

.admin-system-header .unread span[data-count="0"] {
    background:#ccc;
}

.SMSNum {
    display: inline-block;
    text-align: center;
    padding:0;
    line-height: 50px;
    height: 50px;
}

.skrakast_btn {
    display: inline-block;
    width: calc(25% - 0px);
    margin: 0px 0px 10px 0;
    padding: 20px;
    border:1px dashed #c6d0c7;
    border-radius: 8px;
    text-decoration: none;
    color:#161718;
    font-size: 15px;
    transition: 0.3s ease-in-out;
    min-height: 110px;
    vertical-align: top;
}

.skrakast_btn:hover {
    text-decoration: none;
    color:#161718;
    background-color: #6df0f1;
    border:1px dashed transparent;
}

.skrakast_btn strong {
    display: inline-block;
    color:#0a565f;
    background-color: #6df0f1;
    border-radius: 4px;
    font-weight: 500;
    padding: 5px 10px;
    margin-bottom: 5px;
    transition: 0.3s ease-in-out;
}

.skrakast_btn:hover strong {
    background-color: #000;
    color:#fff;
}

.samskipti_btn, .timapantanir_btn, .lokakostum_btn {
    display: inline-block;
    width: calc(25% - 20px);
    margin: 0px 0 10px 20px;
    padding: 20px;
    background-color: #f6f5ee;
    border-radius: 8px;
    text-decoration: none;
    color:#161718;
    font-size: 15px;
    transition: 0.3s ease-in-out;
    border:1px dashed transparent;
    min-height: 110px;
    vertical-align: top;
}

.samskipti_btn:hover,  .timapantanir_btn:hover, .lokakostum_btn:hover {
    text-decoration: none;
    color:#161718;
    background-color: #6df0f1;
}

.samskipti_btn strong, .timapantanir_btn strong, .lokakostum_btn strong {
    display: inline-block;
    color: #fff;
    background-color: #161718;
    border-radius: 4px;
    font-weight: 500;
    padding: 5px 10px;
    margin-bottom: 5px;
}

ul.skilabod {
    list-style-type: none;
    padding: 0px;
    margin:0px 0 10px 0;
    height: 40px;
}

ul.skilabod li {
    display: inline-block;
    margin: 5px;
    width: calc(33.333334% - 10px);
}

ul.skilabod li a {
    padding: 4px 8px;
    border: 1px solid #f6f5ee;
    border-radius: 8px;
    display: block;
    text-align: center;
    color:#56575a;
    text-decoration: none;
}

ul.skilabod li a.selected {
    padding: 4px 8px;
    border: 1px solid #56575a;
    color:#161718;
    background-color: rgba(255,255,255,1);
}

.lifsgaedi {
    margin-bottom: 30px;
}

table.qualityoflife {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e4e4e4;
}

table.qualityoflife thead tr {
    background: #fff;
    border-bottom: 1px solid #e4e4e4;
}

table.qualityoflife th {
    text-align: left;
    padding: 15px 10px;
    font-size: 15px;
    color: #9f9e9e;
    font-weight: 500;
}

table.qualityoflife td {
    text-align: left;
    padding: 15px 10px;
    font-size: 15px;
    color: #000;
}

table.qualityoflife td.grey {
    color: #9f9e9e;
}

table.qualityoflife td.tc, table.qualityoflife th.tc {
    text-align: center;
}

table.qualityoflife td.tr, table.qualityoflife th.tr {
    text-align: right;
}

table.qualityoflife tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

#menu {
    display: none;
}

.smscode {
    display: none;
    margin-top: 10px;
}

.smscode.active {
    display: block;
}

table.qualityoflife a.skodakast {
    font-weight: 600;
    color:#67635d;
    text-decoration: none;
}

table.qualityoflife strong.litur_0  {
    color: rgba(25, 156, 201, 1);
}

table.qualityoflife strong.litur_1 {
    color: rgba(92, 204, 26, 1);
}

table.qualityoflife strong.litur_2 {
    color: rgba(197, 231, 11, 1);
}

table.qualityoflife strong.litur_3 {
    color: rgba(241, 130, 11, 1);
}

table.qualityoflife strong.litur_4 {
    color: rgba(184, 0, 0, 1);
}

.kast_f1820b {
    color: rgba(241, 130, 11, 1);
    background-color: rgba(241, 130, 11, 0);
    border:1px solid rgba(241, 130, 11, 1);
    display: inline-block;
    padding: 4px 8px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 15px;
}
.kast_f1820b:hover {
    color: rgba(241, 130, 11, 1);
    background-color: rgba(241, 130, 11, 0.1);
    text-decoration: none;
}

.kast_b80000 {
    color: rgba(184, 0, 0, 1);
    background-color: rgba(184, 0, 0, 0);
    border:1px solid rgba(184, 0, 0, 1);
    display: inline-block;
    padding: 4px 8px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 15px;
}

.kast_b80000:hover {
    color: rgba(184, 0, 0, 1);
    background-color: rgba(184, 0, 0, 0.1);
    text-decoration: none;
}

.kast_5ccc1a {
    color: rgba(92, 204, 26, 1);
    background-color: rgba(92, 204, 26, 0);
    border:1px solid rgba(92, 204, 26, 1);
    display: inline-block;
    padding: 4px 8px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 15px;
}

.kast_5ccc1a:hover {
    color: rgba(92, 204, 26, 1);
    background-color: rgba(92, 204, 26, 0.1);
    text-decoration: none;
}

.kast_c5e70b {
    color: rgb(173, 201, 13);
    background-color: rgba(197, 231, 11, 0);
    border:1px solid rgba(173, 201, 13, 1);
    display: inline-block;
    padding: 4px 8px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 15px;
}

.kast_c5e70b:hover {
    color: rgba(173, 201, 13, 1);
    background-color: rgba(197, 231, 11, 0.1);
    text-decoration: none;
}

.pagination {
    width:100%;
    margin: 20px 0;
}

.pagination ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    display: inline-block;
    margin:0px 2px 0 0;
    border-radius: 5px;
}

.pagination li a { 
    display: block;
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color:#9f9e9e;
    border-radius: 5px;
    cursor: pointer;
    border:1px solid #C2C2C2;
    background-color: #fff;
    transition: 0.4s ease-in-out;
}

.pagination li.next a, .pagination li.previous a  {
    border:1px solid #C2C2C2;
}

.pagination li.disabled a {
    opacity: 0.4;
    background-color: #fff;
}

.pagination li a:hover {
    color:#ff455f;
    background-color: #eeebe6;
}

.pagination li.selected a {
    background-color: #ff455f;
}

.pagination li.next a:hover, .pagination li.previous a:hover  {
    background-color: #fff;
}

.pagination li.selected a { color:#fff; }


ul.filter {
    list-style-type: none;
    display: inline-block;
    margin: 0 0 20px;
    padding: 0;
}

ul.filter li {
    display: inline-block;
    margin-right: 10px;
}

ul.filter li a {
    display: block;
    padding: 5px 10px;
    border:1px solid #c4c4c3;
    font-size: 14px;
    border-radius: 6px;
}

.lokad {background-color: #DFEDF2;color:#21799F;display: inline-block;padding: 4px 16px;border-radius: 4px;text-decoration: none;font-size: 14px; }
.opid {background-color: #FFEBCC;color:#A0640A;display: inline-block;padding: 4px 16px;border-radius: 4px;text-decoration: none;font-size: 14px;  }
.opid a {text-decoration: none;color:#A0640A; }

.hidethis { display: none !important; }

.verkin .item .upplysingar {
    width:100%;
}

.verkin .item h3 {
    display: inline-block;
    width:75%;
}

.verkin .item p { 
    display: inline-block;
    width:25%;
}