header.header {
    position:fixed;
    left:0px;
    top: 0px;
    height:90px;
    background: rgba(255,255,255,0);
    z-index: 10;
    width:calc(100% - 0px);
    padding: 0px;
    transition: 0.3s ease;
    margin-bottom: 0px;
    clear: both;
    transition: 0.2s ease;
    border-bottom: 1px solid rgba(225,225,225,0);
}


header.header::after {
    position: absolute;
    content: "";
    background: rgb(218,188,56);
    background: linear-gradient(166deg, rgba(218,188,56,0.9) 0%, rgba(255,255,255,0) 46%);
    top:0;
    left:0;
    height: 200px;
    width: 700px;
    z-index: 1;
}

header.header::before {
    position: absolute;
    content: "";
    background: rgb(255,255,255);
    background: linear-gradient(50deg, rgba(255,255,255,0) 0%, rgba(91,172,234,0.6) 37%, rgba(1,132,237,0.5) 82%, rgba(23,59,218,0.7) 96%, rgba(23,59,218,0.8) 100%);
    top: -51px;
    right: 0;
    height: 150px;
    width: 90%;
    z-index: -1;
    filter: blur(43px);
    -webkit-filter: blur(43px);
    opacity: 0.7;
}

header.header i {
    font-size: 24px;
}

header.header h1.merki {
    float: left;
    display: inline-block;
    font-size:28px;
    margin:10px 0;
}

header.header h1.merki a {
    color:#303030;
    text-decoration: none;
}

h1.logo {
    font-weight: 700;
    float: left;
    position: relative;
    height: 36px;
    line-height: 36px;
    z-index: 20;
    font-size: 32px;
    text-indent: 40px;
}

h1.logo::before {
    content: "";
    height: 44px;
    width: 44px;
    background-image: url(../../img/fruma_merki.png);
    position: absolute;
    top: -4px;
    left: -7px;
    z-index: 1;
    background-size: cover;
}

h1.logo a {
    color:#000;
    text-decoration:none;
}
/*
h1.logo::before {
    position: absolute;
    left: 0px;
    top: 0;
    height: 44px;
    width: 44px;
    background: #1297c7;
    border-radius: 27px;
    content: "";
    z-index: 1;
}
*/
#hamborgari {
    width:36px;
    height:40px;
    position:absolute;
    margin:0 auto;
    -webkit-transition:.5s ease-in-out;
    -moz-transition:.5s ease-in-out;
    -o-transition:.5s ease-in-out;
    transition:.5s ease-in-out;
    cursor:pointer;
    top: 0px;
    left: 10px;
    z-index:103;
    display:none
}

#hamborgari span {
    display:block;
    position:absolute;
    height: 4px;
    width: 32px;
    background:#333;
    border-radius:9px;
    opacity:1;
    left:0;
    -webkit-transition:.25s ease-in-out;
    -moz-transition:.25s ease-in-out;
    -o-transition:.25s ease-in-out;
    transition:.25s ease-in-out;
    top:5px
}

#hamborgari span:nth-child(1) {
    left:0
}

#hamborgari span:nth-child(2) {
    left:0;
    top: 14px;
    width: 24px;
}

#hamborgari:hover span:nth-child(2) {
    width: 32px;
}

#hamborgari span:nth-child(3) {
    left:0;
    top:23px;
    width:30px
}

#hamborgari:hover span:nth-child(3) {
    width: 32px;
}

#hamborgari.open span {
    background:#df1a53
}

#hamborgari.open span:nth-child(1) {
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
    top:17px;
    left:2px;
    width:32px
}

#hamborgari.open span:nth-child(2) {
    background:#232323;
    width:0;
    left:15px
}

#hamborgari.open span:nth-child(3) {
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
    top:17px;
    left:2px;
    width:32px
}


ul.menu {
    padding: 0;
    margin:30px 0 0 0; 
    float:right;
    list-style: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 20px;
}

ul.menu li {
    float: left;
    margin: 0 15px;
    position: relative;
    padding-bottom: 0px;
}

ul.menu li a {
    color:#1f1d1d;
    text-decoration: none;
    font-size:16px;
    transition: .30s ease-in-out;
}

ul.menu li a:hover, ul.menu li a.selected {
    color:#ff3a56;
}

ul.menu li.btn, ul.menu li.btn_inn {
    float: left;
    margin: 0 0px 0 15px;
}

ul.menu li.divider {
    border-right: 1px solid #d5ceca;
    padding-right: 30px;
    margin-right: 14px;
}

ul.submenu {
    position: absolute;
    top: 30px;
    left: calc(50% - 200px);
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display:none;
}

li:hover ul.submenu {
    display:block;
}

ul.submenu.company {
    width:400px !important
}

ul.menu li ul.submenu li { 
    margin:0 10px;
}

ul.submenu li a {
    font-size: 16px !important;
    font-weight: 500;
}

ul.menu li.btn a {
    border:1px solid #e9e9e9;
    display: block;
    padding:8px 16px;
    margin-top: -7px;
    border-radius: 8px;
    transition: .30s ease-in-out;
}

ul.menu li.btn a:hover {
    text-decoration: none;
    background: #f9f9f9;
}

ul.menu li.btn_inn a {
    border: none;
    display: block;
    padding:5px 7px;
    margin-top: -6px;
    background: transparent;
    color:#000;
    transition: .30s ease-in-out;
}
ul.menu li.btn_inn_admin {
    margin: 0 0 0 5px;
}
ul.menu li.btn_inn_admin a {
    border: none;
    display: block;
    padding:5px 7px;
    margin-top: -6px;
    background: transparent;
    color:#000;
    transition: .30s ease-in-out;
}

ul.menu li.btn_inn a:hover {
    text-decoration: none;
    color:#ff455f;
}

ul.menu li.btn_inn_admin a:hover {
    text-decoration: none;
    color:#ff455f;
}

ul.menu li.btn_inn.valid a {
    border:1px solid #df1a53;
    background: #df1a53;
}

ul.menu li.btn_inn.valid a:hover {
    border:1px solid #df1a53;
    background: #df1a53;
}

.adgangur {
    color:#949494;
    margin: 40px 0;
    text-align: center;
}

.adgangur a {
    color:#1c78e3;
    font-weight: 500;
}

.takki {
    display: inline-block;
    padding:8px 26px;
    border-radius: 8px;
    border: none;
    background: #d91833;
    border: 1px solid #d91833;
    color:#fff !important;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500 !important;
    font-size: 18px;
    text-decoration: none !important;
    transition: .30s ease-in-out;
}

.takki:hover {
    text-decoration: none;
    background: #000;
    border: 1px solid #000;
    color:#fff !important;
}

button.innskraning {
    background-color:transparent;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

button.innskraning:hover {
    color:#ab0b25;
}

.merki {
    width:30px;
    height:36px;
    display: inline-block;
    vertical-align: top;
    line-height: 4px;
    margin-right: 12px;
}

.merki span {
    width:10px;
    height:4px;
    display: inline-block;
    vertical-align: top;
    background-color: #3E3A6E;
    line-height:4px;
    font-size: 1px;
}

.merki span:nth-child(2) {
    opacity: 0.8;
}

.merki span:nth-child(3) {
    opacity: 0.6;
}

.merki span:nth-child(4) {
    opacity: 0.4;
}

.merki span:nth-child(5) {
    opacity: 0.2;
    margin-right:10px
}

.merki span:nth-child(6) {
    opacity: 0.7;
    margin-right:20px;
    background-color: #EBBB44;
}

.merki span:nth-child(7) {
    opacity: 1;
    background-color: #EBBB44;
}

.merki span:nth-child(8) {
    opacity: 0.8;
    background-color: #EBBB44;
}

.merki span:nth-child(9) {
    opacity: 0.1;
    background-color: #EBBB44;
}

.merki span:nth-child(10) {
    opacity: 1;
    background-color: #E75251;
}

.merki span:nth-child(11) {
    opacity: 0.7;
    background-color: #EBBB44;
}

.merki span:nth-child(12) {
    opacity: 0.5;
    background-color: #EBBB44;
}

.merki span:nth-child(13) {
    opacity: 0.9;
    background-color: #E75251;
}

.merki span:nth-child(14) {
    opacity: 0.5;
    background-color: #E75251;
}

.merki span:nth-child(15) {
    opacity: 0.1;
    background-color: #E75251;
}

.merki span:nth-child(16) {
    opacity: 0.6;
    background-color: #E75251;
    margin-right:20px
}

.merki span:nth-child(17) {
    opacity: 1;
    background-color: #72C3EC;
}

.merki span:nth-child(18) {
    opacity: 0.5;
    background-color: #72C3EC;
}


.merki span:nth-child(19) {
    opacity: 0.1;
    background-color: #72C3EC;
}

.merki span:nth-child(20) {
    opacity: 0.8;
    background-color: #72C3EC;
}

.merki span:nth-child(21) {
    opacity: 0.6;
    background-color: #72C3EC;
}


.merki span:nth-child(22) {
    opacity: 0.4;
    background-color: #72C3EC;
}

.admin-system-header {
    width: 100%;
    height: 45px;
    background: rgba(255,255,255,0.4);
    display: block;
    padding: 0;
    margin: 10px 0 20px;
    border-bottom: 1px dotted #e5e1d9;
  }

.admin-system-header h1 {
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
}

.admin-system-header .userprofile {
    height:30px;
}

.admin-system-header .userprofile .username {
    height:30px;
    line-height:30px;
}

.admin-system-header .userprofile i {
    font-size: 20px;
    margin-top: 4px;
}

.admin-system-header .adgerdir {
    height:30px;
    float: right;
    margin-right: 10px;
    font-size: 20px;
    margin-top: 3px;
}

.admin-system-header .adgerdir i {
    margin-right: 10px;
}