section.banner {
    position: relative;
    width: 100%;
    height:640px;
    float: left;
    z-index: 1;
}

.fyrirsogn {
    max-width: 55%;
    font-size: 44px;
    margin: 20px auto;
    font-weight: 400;
    text-align: center;
}

.fyrirsogn strong {
    font-weight: 900;
}

.yfirfyrirsogn {
    max-width: 55%;
    font-size: 18px;
    font-weight: 300;
    color:#ff455f;
    margin: 170px 0 0 0;
    opacity: 0;
}

.undirfyrirsogn {
    max-width: 55%;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
}

.aherslur {
    width:100%;
    margin: -50px 0 100px;
    display: inline-block;
}

.ahersla {
    width:33.33334%;
    display: inline-block;
    vertical-align: top;
    padding: 0 30px;
}

.ahersla:last-child {
    padding-right: 0px;
}

.ahersla figure {
    height:180px;
    background-color: #fff;
    border: 1px solid #f9f9f9;
    margin: 10px 0;
    border-radius:5px;
    background-size: cover;
}

.aherslur h2 {
    text-align: center;
    margin: 40px auto 30px;
    font-weight: 400;
    font-size: 34px;
    max-width: 400px;
}

.aherslur h3 {
    text-align: center;
    margin: 0 0 40px;
    font-weight: 400;
    font-size: 28px;
    color:#686868;
}

.aherslur .ahersla h3 {
    color:#000;
    text-align: left;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
}

.aherslur .ahersla  p {
    margin: 10px 0;
    font-size: 16px;
}


